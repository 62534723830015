import {getLastKnownRoute} from 'components/util/getLastKnownRoute';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

const LAST_KNOWN_ROUTE_KEY = 'lastKnownRoute';
const LOGOUT_ROUTE = 'logout';
const NON_SPECIFIC_ROUTES = ['/', '', '/login', 'login', null, LOGOUT_ROUTE];

const useLastKnownRoute = () => {
    const router = useRouter();

    useEffect(() => {
        const path = router.asPath;

        const lastKnownRoute = getLastKnownRoute();

        if (
            path &&
            path !== lastKnownRoute &&
            lastKnownRoute !== LOGOUT_ROUTE
        ) {
            window.localStorage.setItem(
                LAST_KNOWN_ROUTE_KEY,
                JSON.stringify(path)
            );
        }
    }, [router.asPath]);

    useEffect(() => {
        return () => {
            const lastKnownRoute = getLastKnownRoute();

            if (lastKnownRoute === LOGOUT_ROUTE) {
                window.localStorage.removeItem(LAST_KNOWN_ROUTE_KEY);
            }
        };
    }, []);
};

export {
    useLastKnownRoute,
    getLastKnownRoute,
    LAST_KNOWN_ROUTE_KEY,
    NON_SPECIFIC_ROUTES,
    LOGOUT_ROUTE,
};
