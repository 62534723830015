import {LAST_KNOWN_ROUTE_KEY} from 'components/hooks/useLastKnownRoute';

export const getLastKnownRoute = (): string => {
    try {
        const route = localStorage.getItem(LAST_KNOWN_ROUTE_KEY);
        return route ? (JSON.parse(route) as string) : '';
    } catch (e) {
        return '';
    }
};
